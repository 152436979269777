export enum DOOR_OPENING_TYPES {
  'RIGHT' = 'Вправо',
  'LEFT' = 'Вліво',
}

export enum DOOR_CLASSES {
  'ECONOMY' = 'Економ',
  'STANDART' = 'Стандарт',
  'PRESTIGE' = 'Престиж',
}
