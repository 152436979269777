import './style.css';

export const ProductDoorGuarantee = () => {
  return (
    <div className="product-door-guarantee-wrapper">
      <h2>Умови гарантії</h2>
      <p>Термін гарантії на двері починається з моменту отримання їх від служби доставки.</p>
      <p>Терміни дії гарантії:</p>
      <ul className="product-door-guarantee-list">
        <li>
          <p className="product-door-guarantee-list-item">
            Гарантійний термін на основну конструкцію дверей, що включає каркас створки, рами, лист
            металу створки та петлі, становить 1 рік з дати їх виготовлення.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Гарантійний термін на циліндричний механізм та комплектуючі, такі як вічко, ручки,
            ущільнювач та накладки на замки, становить 6 місяців.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Гарантійний термін на декоративні накладки становить 1 рік.
          </p>
        </li>
      </ul>
      <p>Гарантія не охоплює комплектуючі, які надає замовник.</p>
      <p>
        Монтаж дверей повинен здійснюватися кваліфікованими фахівцями. Водночас, особа, яка
        встановлює виріб, несе повну відповідальність за якість монтажу. Ми наголошуємо на
        важливості правильної установки дверей як для забезпечення їх надійного використання, так і
        для забезпечення можливості отримання гарантійного обслуговування.{' '}
      </p>
      <p>
        Виробник не несе відповідальності за будь-яку можливу шкоду, що може бути прямо чи
        опосередковано завдана продуктом через необережні дії споживача чи інших осіб, а також за
        будь-які можливі збитки, які споживач може зазнати під час періоду, коли він не мав
        можливості користуватися виробом.
      </p>
      <p>Гарантійні зобов'язання на виріб не застосовуються у наступних ситуаціях:</p>
      <ul className="product-door-guarantee-list">
        <li>
          <p className="product-door-guarantee-list-item">
            коли замовник самостійно встановлює додаткове обладнання, не входять до сфери
            гарантійного обслуговування.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            порушені правила установки і експлуатації, викладені в даному паспорті;
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            на виробі присутні ознаки некваліфікованого ремонту;
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Миючі засоби з активними компонентами, а також агресивні рідини, що використовувалися,
            призвели до пошкодження оздоблювального покриття та (або) фурнітури виробу.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Мастильні матеріали замерзли, на полотні та замках з'явився конденсат і нальот, а також
            з'явилася корозія на замках та металевих частинах полотна через відсутність сухого та
            провітрюваного тамбура між дверима та основним приміщенням.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">форс-мажорних обставин;</p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            cтіхійних лих (пожежа, землетрус, повінь і т.п.).
          </p>
        </li>
      </ul>
      <strong>Рекомендовані умови експлуатації:</strong>
      <ul className="product-door-guarantee-list">
        <li>
          <p className="product-door-guarantee-list-item">
            Рекомендовано використовувати засоби по догляду за меблями та м'яку вологу тканину для
            очищення від забруднень. Варто уникати застосування агресивних миючих засобів, таких як
            розчинники, луги або кислоти, що можуть пошкодити виріб.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Уникати утворення конденсату на рамі та дверях;
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Якщо двері встановлюються з виходом на вулицю, то необхідно мати захисний зовнішній
            козирок та бажано встановити прохолодний тамбур всередині приміщення.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Розмір козирка для дверей є індивідуальним для кожного розміру двері, і також повинен
            враховувати рух сонця щодо дверей протягом дня. Рекомендується уникати різких перепадів
            температур та прямих сонячних променів на виробі.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Необхідно уникати прямого контакту виробу з поверхнями, температура яких може
            перевищувати 60°С, щоб уникнути можливих пошкоджень або деформації виробу.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Замки в дверях мають бути захищені від термічного, хімічного та механічного впливу.
            Важливо пам'ятати, що якщо ви самостійно розбираєте або модифікуєте замки, то вони
            можуть втратити гарантію на обслуговування.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Необхідно проводити щорічне змащування петель та налагодження запірних механізмів, що
            має здійснюватися компетентними фахівцями.
          </p>
        </li>
        <li>
          <p className="product-door-guarantee-list-item">
            Можливо, що після тривалого використання дверей, дверне полотно може стати нещільним,
            коли дверна защіпка закрита. У цьому випадку, рекомендується замінити ущільнювач.
          </p>
        </li>
      </ul>
    </div>
  );
};
